<template>
  <div class="explainall mywidth box-bg">
<!--    <div class="tit">-->
<!--      <span  @click="getid('#tbr1')">注册</span>-->
<!--      <span  @click="getid('#tbr2')">申请河北CA</span>-->
<!--      <span  @click="getid('#tbr3')">CA绑定</span>-->
<!--    </div>-->
    <div class="explain">
      <h2>操作指南</h2>
      <h3 id="tbr1">注册</h3>
      <p>1. 点击“新用户注册”按钮，进入注册页面。</p>
      <img src="./tbrsmimg/zc1.png" style="width:50% ;">
      <p> 2. 填写选择账户类型，填写用户资料。</p>
      <img src="./tbrsmimg/zc2.png">
      <img src="./tbrsmimg/zc3.png">
      <h3 id="tbr2">申请河北CA</h3>
      <p>1. 点击网站主菜单上的CA办理</p>
      <img src="./tbrsmimg/sq1.png">
      <p>2. 点击“新办证书业务办理”填写办理资料</p>
      <img src="./tbrsmimg/sq2.png">
      <p>3. 按顺序填写资料。</p>
      <img src="./tbrsmimg/sq3.png">
      <h3 id="tbr3">CA绑定</h3>
      <p>1. 新的CA收到后，将UsbKey插入电脑U口。用我们申请到的帐号，登录系统。</p>
      <img src="./tbrsmimg/cabd1.png"  style="width:50% ;">
      <p>2. 点击左侧菜单中“系统管理/职业人员管理”。点击对应人员中，“CA绑定”按钮。</p>
      <img src="./tbrsmimg/cabd2.png">
      <p>3. 绑定CA锁。</p>
      <img src="./tbrsmimg/cabd3.png">
      <p>4. 测试CA锁。</p>
      <img src="./tbrsmimg/cabd4.png"  style="width:50% ;">
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  methods:{
    getid(selector){
      var anchor = this.$el.querySelector(selector) // 获取元素
      if (anchor) {
        setTimeout(() => { // 页面没有渲染完成时是无法滚动的，因此设置延迟
          anchor.scrollIntoView({behavior: 'smooth'}) // js的内置方法，可滚动视图位置至元素位置
        }, 200)
      }
    }
  }

}

</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.explainall{
  margin: 20px auto 20px;
}
.explain {
  margin: 20px auto 20px;
  max-width: 690px;
  background: #ffffff;
  padding: 30px 40px;
  text-align: center;
}

.explain h2 {
  margin-bottom: 20px;
}

.explain h3 {
  margin: 20px 0 -10px;
  line-height: 40px;
  text-align: left;

}
.explain h3 a,.explain h4 a{
  padding-top: 10px;
  color: #333;
}
.explain h4 {
  margin: 20px 0 -10px;
  line-height: 40px;
  text-align: left;
}

.explain p {
  margin: 25px 0 15px;
  text-align: left;
}

.explain img {
  width: 100%;
}

.explain p {
  text-align: left;
}

.tit {
  position: fixed;
  top: 180px;
  background: #ffffff;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 4px 0 0 4px;
}

.tit span {
  display: block;
  background: #DDAC45;
  font-size: 15px;
  margin: 5px 0;
  color: #ffffff !important;
  line-height: 40px;
  text-decoration: none;
  border-radius: 4px;
  width: 152px;
  text-align: center;
  cursor: pointer;
}

</style>
